"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menuListApi = menuListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * 菜单
 * @param pram
 */
function menuListApi() {
  return (0, _request.default)({
    url: '/admin/merchant/getMenus',
    method: 'GET'
  });
}