"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formTempNameInfoApi = formTempNameInfoApi;
exports.getFormConfigInfo = getFormConfigInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getFormConfigInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/merchant/form/temp/info',
    method: 'GET',
    params: data
  });
}

/**
 * 通过名称查询详情
 * @param pram
 */
function formTempNameInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/form/temp/name/info",
    method: 'get',
    params: params
  });
}