"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
var _yearOptions = _interopRequireDefault(require("@/libs/yearOptions"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'statement',
  data: function data() {
    return {
      timeVal: [],
      activeName: 'day',
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        dateLimit: ''
      },
      dialogVisible: false,
      accountDetails: {},
      pickerOptions: this.$timeOptions,
      pickerOptionsYear: _yearOptions.default
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:finance:daily:statement:page:list', 'merchant:finance:month:statement:page:list'])) this.getList(1);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.getList(1);
    },
    handleClick: function handleClick() {
      this.tableFrom.dateLimit = '';
      this.timeVal = [];
      this.getList(1);
    },
    onDetails: function onDetails(date) {
      this.dialogVisible = true;
      this.accountDetails = date;
    },
    seachList: function seachList() {
      this.handleClose();
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.activeName === 'day' ? (0, _finance.dayStatementApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      }) : (0, _finance.monthStatementApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure().then(function () {
        storeApi.brandDeleteApi(id).then(function (res) {
          _this2.$message.success('删除成功');
          _this2.$store.commit('merchant/SET_MerchantClassify', []);
          _this2.getList();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      activityApi.activitySwitchApi(row.id).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.getList();
      });
    }
  }
};