"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.captchaApi = captchaApi;
exports.getInfo = getInfo;
exports.getLoginPicApi = getLoginPicApi;
exports.login = login;
exports.logout = logout;
exports.userDetailApi = userDetailApi;
exports.userListApi = userListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function login(data) {
  return (0, _request.default)({
    url: '/admin/merchant/login',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/admin/merchant/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/admin/merchant/logout',
    method: 'get'
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
function userListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/user/list",
    method: 'get',
    params: params
  });
}

/**
 *获取登录页图片
 */
function getLoginPicApi() {
  return (0, _request.default)({
    url: "/admin/merchant/getLoginPic",
    method: 'get'
  });
}

/**
 * @description 验证码
 */
function captchaApi() {
  return (0, _request.default)({
    url: "/publicly/validate/code/get",
    method: 'get'
  });
}

/**
 * @description 用户详情列表
 */
function userDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/user/detail/".concat(id),
    method: 'get'
  });
}