function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { querySubInfo } from '@/api/finance';
import { saveAs } from "file-saver";
import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
import XLSX from "xlsx";
export default {
  name: 'separateDetail',
  data: function data() {
    return {
      currentPage: 1,
      //当前第几页
      pageSize: 10,
      //每页数量
      total: 0,
      //总数
      timeList: [this.formatDates(), this.formatDates()],
      queryParams: {
        beginTime: this.formatDates(),
        endTime: this.formatDates()
      },
      //搜索表单
      tableData: [],
      projectTypeList: [{
        value: 1,
        label: '平台运营方'
      }, {
        value: 2,
        label: '小北鲜市'
      }, {
        value: 3,
        label: '社区'
      }, {
        value: 4,
        label: '商家让利'
      }]
    };
  },
  computed: _objectSpread({}, mapGetters(['merchantClassify', 'merchantType'])),
  filters: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    formatDates: function formatDates(e) {
      console.log(e);
      if (!e) {
        e = new Date();
      }
      var date = e;
      var formatObj = {
        y: date.getFullYear(),
        m: ('0' + (date.getMonth() + 1)).slice(-2),
        d: ('0' + date.getDate()).slice(-2),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      };
      return "".concat(formatObj.y, "-").concat(formatObj.m, "-").concat(formatObj.d);
    },
    timeChange: function timeChange(e) {
      console.log(e);
      this.queryParams.beginTime = e[0];
      this.queryParams.endTime = e[1];
    },
    handleQuery: function handleQuery() {
      this.currentPage = 1;
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.currentPage = 1;
      this.timeList = [this.formatDates(), this.formatDates()];
      this.queryParams = {
        beginTime: this.formatDates(),
        endTime: this.formatDates()
      };
      this.getList();
    },
    getList: function getList(obj) {
      var _this = this;
      Loading.service();
      console.log(this.queryParams);
      var data = _objectSpread({
        limit: this.pageSize,
        page: this.currentPage
      }, this.queryParams);
      querySubInfo(data).then(function (res) {
        _this.total = res.total;
        if (res.accList) {
          _this.tableData = res.accList.map(function (item) {
            item.debitAmount = "\uFFE5".concat(Number(item.debitAmount) / 100);
            item.loanAmount = "\uFFE5".concat(Number(item.loanAmount) / 100);
            item.balance = "\uFFE5".concat(Number(item.balance) / 100);
            return item;
          });
        } else {
          _this.tableData = [];
        }
        Loading.service().close();
      }).catch(function (err) {
        console.log(err);
        Loading.service().close();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.getList(this.queryParams);
    },
    Datechange: function Datechange(val) {
      console.log(val);
      if (val === null) {
        this.form.date = '';
        this.form.endTime = '';
        this.form.startTime = '';
      }
    },
    // 导出
    exportAllData: function exportAllData() {
      var _this2 = this;
      querySubInfo({
        limit: 9999999
      }).then(function (res) {
        _this2.exportDataToExcel(res.list, '明细.xlsx');
      }).catch(function (err) {
        console.log(err);
      });
    },
    exportDataToExcel: function exportDataToExcel(data, filename) {
      // 1. 获取表头
      var headerRow = Array.from(this.$refs.table.$el.querySelectorAll('.el-table__header-wrapper th'));
      var labelValues = headerRow.map(function (header) {
        return header.textContent.trim(); // 使用textContent获取表头文本
      });
      // 去除数组里面是空的
      var newList = labelValues.filter(function (item) {
        return item !== "";
      });
      // 2. 获取数据
      var dataArray = data.map(function (obj, index) {
        var arr = [index, obj.communityName, obj.integralRuleIdMiddleName, obj.integralRuleIdSmallName, obj.integral = (obj.operateType == 1 ? '+' : '-') + obj.integral, obj.updateTime, obj.createdBy, obj.mark];
        return arr;
      });
      dataArray.unshift(newList);
      // 3. 导出Excel
      var worksheet = XLSX.utils.aoa_to_sheet(dataArray);
      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, filename);
    }
  }
};