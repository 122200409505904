"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/echartsNew/index"));
var _dashboard = require("@/api/dashboard");
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    echartsNew: _index.default
  },
  data: function data() {
    return {
      grid: {
        xl: 3,
        lg: 3,
        md: 6,
        sm: 8,
        xs: 8
      },
      nav_list: [
      // {
      //   bgColor: '#E8B500',
      //   icon: 'icon-youhuiquan',
      //   title: '优惠券',
      //   url: '/coupon/list',
      //   perms: ['merchant:coupon:page:list'],
      // },
      {
        bgColor: '#4BCAD5',
        icon: 'icon-shangpinguanli',
        title: this.$t('dashboard.components.gridMenu.5u3ipbatim40'),
        url: '/product/list',
        perms: ['merchant:product:page:list']
      }, {
        bgColor: '#A277FF',
        icon: 'icon-a-dingdanguanli1',
        title: this.$t('dashboard.components.gridMenu.5u3ipbatink0'),
        url: '/order/list',
        perms: ['merchant:order:page:list']
      }, {
        bgColor: '#1890FF',
        icon: 'icon-xitongshezhi',
        title: this.$t('dashboard.components.gridMenu.5u3ipbatipc0'),
        url: '/operation/modifyStoreInfo',
        perms: ['merchant:base:info']
      }, {
        bgColor: '#EF9C20',
        icon: 'icon-caiwuguanli',
        title: this.$t('dashboard.components.gridMenu.5u3ipbatiqw0'),
        url: '/finance/capitalFlow',
        perms: ['merchant:finance:page:capitalFlow']
      }],
      statisticData: [{
        title: this.$t('dashboard.components.gridMenu.5u3ipbatisg0'),
        num: 0,
        path: '/product/list',
        perms: ['merchant:product:page:list']
      }, {
        title: this.$t('dashboard.components.gridMenu.5u3ipbatiuc0'),
        num: 0,
        path: '/product/list',
        perms: ['merchant:product:page:list']
      }, {
        title: this.$t('dashboard.components.gridMenu.5u3ipbatiw40'),
        num: 0,
        path: '/order/list',
        perms: ['merchant:order:page:list']
      }, {
        title: this.$t('dashboard.components.gridMenu.5u3ipbatixg0'),
        num: 0,
        path: '/order/list',
        perms: ['merchant:order:page:list']
      }, {
        title: this.$t('dashboard.components.gridMenu.5u3ipbatizs0'),
        num: 0,
        path: '/order/refund',
        perms: ['merchant:order:page:refund']
      }],
      optionData: {},
      applyNum: 0,
      style: {
        height: '250px'
      }
    };
  },
  computed: {
    //鉴权处理
    permList: function permList() {
      var _this = this;
      var arr = [];
      this.nav_list.forEach(function (item) {
        if (_this.checkPermi(item.perms)) {
          arr.push(item);
        }
      });
      return arr;
    },
    businessList: function businessList() {
      var _this2 = this;
      var arr = [];
      this.statisticData.forEach(function (item) {
        if (_this2.checkPermi(item.perms)) {
          arr.push(item);
        }
      });
      return arr;
    }
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:statistics:home:operating:data'])) this.getbusinessData();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    navigatorTo: function navigatorTo(path) {
      this.$router.push(path);
    },
    getbusinessData: function getbusinessData() {
      var _this3 = this;
      (0, _dashboard.businessData)().then(function (res) {
        _this3.statisticData[0].num = res.onSaleProductNum; //在售商品数量
        _this3.statisticData[1].num = res.awaitAuditProductNum; //待审核商品数量
        _this3.statisticData[2].num = res.notShippingOrderNum; //待发货订单数量
        _this3.statisticData[3].num = res.awaitVerificationOrderNum; //待核销订单数量
        _this3.statisticData[4].num = res.refundingOrderNum; //待退款订单数量
      });
    }
  }
};