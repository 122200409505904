"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _finance = require("@/api/finance");
var _store = _interopRequireDefault(require("@/store"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20
      },
      data: {},
      listLoading: false,
      keyNum: 0,
      id: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      _elementUi.Loading.service();
      (0, _finance.merAccountSelect)().then(function (res) {
        console.log(res);
        _this2.data = res.businessData;
        _this2.data.MerchantName = res.EbMerchant.merchantName;
        _elementUi.Loading.service().close();
      }).catch(function (res) {
        _elementUi.Loading.service().close();
      });
    },
    addDecimalPoint: function addDecimalPoint(numStr, position) {
      return '￥' + Number(numStr) / 100;
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      var _this = this;
      this.id = editDate ? editDate.id : 0;
      this.$modalParserFrom(isCreate === 0 ? '新建分类' : '编辑分类', '商户分类', isCreate, isCreate === 0 ? {
        id: 0,
        name: '',
        handlingFee: ''
      } : Object.assign({}, editDate), function (formValue) {
        _this.submit(formValue);
      }, this.keyNum += 2);
    },
    submit: function submit(formValue) {
      var _this3 = this;
      var data = {
        id: this.id,
        name: formValue.name,
        handlingFee: formValue.handlingFee
      };
      !this.id ? merchant.merchantCategoryAddApi(data).then(function (res) {
        _this3.$message.success(_this3.$t('merchant.classify.index.5thgosgfcwg0'));
        _this3.$msgbox.close();
        _this3.$store.commit('merchant/SET_MerchantClassify', []);
        _this3.getList();
      }).catch(function () {
        _this3.loading = false;
      }) : merchant.merchantCategoryUpdateApi(data).then(function (res) {
        _this3.$message.success(_this3.$t('merchant.classify.index.5thgosgfcwg0'));
        _this3.$msgbox.close();
        _this3.$store.commit('merchant/SET_MerchantClassify', []);
        _this3.getList();
      }).catch(function () {
        _this3.loading = false;
      });
    },
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this4 = this;
      this.$modalSure(this.$t('merchant.classify.index.5thgosgfd4g0')).then(function () {
        merchant.merchantCategoryDeleteApi(rowData.id).then(function (data) {
          _this4.$message.success(_this4.$t('merchant.classify.index.5thgosgfda40'));
          _this4.getList(1);
          _this4.$store.commit('merchant/SET_MerchantClassify', []);
        });
      });
    }
  }
};