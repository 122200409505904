function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as logistics from '@/api/logistics';
import { Loading } from 'element-ui';
import { Debounce } from '@/utils/validate';
import MapContainer from '@/components/MapContainer/MapContainer.vue';
import * as selfUtil from '@/utils/ZBKJIutil.js';
var defaultRole = {
  name: '',
  type: 1,
  appoint: 0,
  sort: 0,
  pickerOptions: {
    shortcuts: [{
      text: '最近一周',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  },
  region: [{
    first: 1,
    firstPrice: 0,
    renewal: 0,
    renewalPrice: 0,
    city_ids: [],
    cityId: 0
  }],
  undelivery: 0,
  free: [],
  undelives: {},
  streetBackImage: ''
};
var kg = '重量（kg）';
var m = '体积（m³）';
var statusMap = [{
  title: '首件',
  title2: '续件',
  title3: '包邮件数'
}, {
  title: "\u9996\u4EF6".concat(kg),
  title2: "\u7EED\u4EF6".concat(kg),
  title3: "\u5305\u90AE".concat(kg)
}, {
  title: "\u9996\u4EF6".concat(m),
  title2: "\u7EED\u4EF6".concat(m),
  title3: "\u5305\u90AE".concat(m)
}];
export default {
  name: 'CreatTemplates',
  components: {
    MapContainer: MapContainer
  },
  data: function data() {
    return {
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入提货点名称',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入提货点电话',
          trigger: 'blur'
        }],
        addressDetail: [{
          required: true,
          message: '请输入提货点地址',
          trigger: 'blur'
        }],
        businessTime: [{
          required: true,
          message: '请输入提货点营业时间',
          trigger: 'blur'
        }],
        streetBackImage: [{
          required: true,
          message: '请添加提货点logo',
          trigger: 'blur'
        }]
      },
      nodeKey: 'city_ids',
      props: {
        children: 'child',
        label: 'regionName',
        value: 'regionId',
        multiple: true
      },
      dialogVisible: false,
      ruleForm: Object.assign({}, defaultRole),
      listLoading: false,
      cityList: [],
      columns: {
        title: '首件',
        title2: '续件',
        title3: '包邮件数'
      },
      tempId: 0,
      regionNew: [{
        first: 1,
        firstPrice: 0,
        renewal: 0,
        renewalPrice: 0,
        city_ids: [],
        cityId: 0
      }],
      type: 0 // 0添加 1编辑
    };
  },
  mounted: function mounted() {
    var cityList = localStorage.getItem('cityList') ? JSON.parse(localStorage.getItem('cityList')) : [];
    // this.cityList = this.changeNodes(cityList);
    this.cityList = this.changeNodes(cityList);
  },
  methods: {
    // 点击商品图
    modalPicTap: function modalPicTap(tit, val, maxSize) {
      var _this = this;
      this.$modalUpload(function (img) {
        // _this.$nextTick(() => {
        //   _this.ruleForm.backImage = img[0].sattDir;
        // });
        switch (val) {
          case 'avatar':
            _this.ruleForm.avatar = img[0].sattDir;
            break;
          case 'backImage':
            _this.ruleForm.streetBackImage = img[0].sattDir;
            console.log(img, val, _this.ruleForm.streetBackImage, 'imgimgimg');
            console.log(img, val, 'imgimgimg');
            break;
          case 'rectangleLogo':
            _this.ruleForm.rectangleLogo = img[0].sattDir;
            break;
          // case 'coverImage':
          //   _this.ruleForm.coverImage = img[0].sattDir;
          //   break;
          // default:
          //   _this.ruleForm.streetBackImage = img[0].sattDir;
          //   break;
        }
      }, tit, 'content', '', maxSize);
    },
    getLocation: function getLocation(e) {
      console.log(e);
      this.ruleForm.latitude = e.latitude;
      this.ruleForm.longitude = e.longitude;
      // this.merInfoForm.addressDetail = e.addressDetail
    },
    changeAppoint: function changeAppoint() {
      var region = _toConsumableArray(this.ruleForm.region);
      // if(this.type === 1){
      //   if (Number(this.ruleForm.appoint) === 1) {
      //     this.ruleForm.region = region.filter((item,index) => {
      //       if(item.city_ids.length > 0) return item.city_ids[0][0] === 0
      //     })
      //   }
      //   else {
      //     this.ruleForm.region = this.regionNew
      //   }
      // }
    },
    changType: function changType(type) {
      this.type = type;
    },
    confirmEdit: function confirmEdit(row, index) {
      row.splice(index, 1);
    },
    popoverHide: function popoverHide() {},
    handleClose: function handleClose() {
      var _this2 = this;
      this.dialogVisible = false;
      setTimeout(function () {
        _this2.ruleForm = {
          name: '',
          type: 1,
          appoint: 0,
          sort: 0,
          region: [{
            first: 0,
            firstPrice: 0,
            renewal: 0,
            renewalPrice: 0,
            city_ids: [],
            cityId: 0
          }],
          undelivery: 0,
          free: [],
          undelives: {}
        };
      }, 1000);
      this.columns = Object.assign({}, statusMap[0]);
    },
    changeNodes: function changeNodes(data) {
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (!data[i].child || data[i].child.length < 1) {
            // this.$delete(data[i], 'child');
            data[i].child = undefined;
          } else {
            this.changeNodes(data[i].child);
          }
        }
      }
      return data;
    },
    changeRegion: function changeRegion(value) {
      // let val = value;
      // let attr = JSON.parse(JSON.stringify(val));
      // for (var i = 0; i < attr.length; i++) {
      //   attr[i].splice(0, 2);
      // }
      // let cityTree = attr.flat();
      // for (var i = 0; i < this.cityList.length; i++) {
      //   for (var j = 0; j < this.cityList[i].child.length; j++) {
      //     for (var w = 0; w < this.cityList[i].child[j].child.length; w++) {
      //       if (!this.cityList[i].child[j].child[w]) return;
      //       if (cityTree.includes(this.cityList[i].child[j].child[w].regionId)) {
      //         this.cityList[i].child[j].child[w].disabled = true;
      //       }
      //     }
      //   }
      // }
    },
    judgeDisabled: function judgeDisabled(cityList, cityTree) {
      var _this3 = this;
      cityList.map(function (item, index) {
        item.disabled = item.regionId === cityTree[index];
        if (item.child && item.child.length) {
          _this3.judgeDisabled(item.child);
        }
      });
    },
    changeRadio: function changeRadio(num) {
      this.columns = Object.assign({}, statusMap[num - 1]);
    },
    // 添加配送区域
    addRegion: function addRegion(region) {
      region.push(Object.assign({}, {
        first: 0,
        firstPrice: 0,
        renewal: 0,
        renewalPrice: 0,
        city_ids: [],
        cityId: ''
      }));
    },
    addFree: function addFree(Free) {
      Free.push(Object.assign({}, {
        number: 1,
        price: 1,
        city_ids: [],
        cityId: ''
      }));
    },
    /**
     * 详情
     * id 模板id
     * appoint true包邮 false不包邮
     **/
    getInfo: function getInfo(id, appoint) {
      var _this4 = this;
      this.tempId = id;
      var loadingInstance = Loading.service({
        fullscreen: true
      });
      logistics.updateDataApi({
        id: id
      }).then(function (res) {
        _this4.ruleForm = res;
        console.log(_this4.ruleForm, res, 'this.ruleForm');
        _this4.dialogVisible = true;
        var info = res;
        // this.regionNew = [...this.ruleForm.region];
        _this4.$nextTick(function () {
          loadingInstance.close();
        });
      }).catch(function (res) {
        // console.integralLog(res)
        _this4.$message.error(res.message);
        _this4.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    // 列表
    getCityList: function getCityList() {
      var _this5 = this;
      logistics.cityListTree().then(function (res) {
        localStorage.setItem('cityList', JSON.stringify(res));
        var cityList = JSON.parse(localStorage.getItem('cityList'));
        _this5.cityList = cityList;
        _this5.changeNodes(cityList);
      }).catch(function (res) {
        _this5.$message.error(res.message);
      });
    },
    change: function change(idBox) {
      idBox.map(function (item) {
        var ids = [];
        item.city_ids.map(function (j) {
          j.splice(0, 1);
          ids.push(j[0]);
        });
        item.city_ids = ids;
      });
      return idBox;
    },
    changeOne: function changeOne(idBox) {
      var city_ids = [];
      idBox.map(function (item) {
        item.splice(0, 1);
        city_ids.push(item[0]);
      });
      return city_ids;
    },
    onsubmit: Debounce(function (formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        _this6.handlerSaveOrUpdate();
      });
    }),
    handlerSaveOrUpdate: function handlerSaveOrUpdate() {
      var _this7 = this;
      // 新增;
      if (this.type == 0) {
        this.loadingBtn = true;
        logistics.exportAddApi(this.ruleForm).then(function (data) {
          _this7.$emit('hideEditDialog');
          _this7.$message.success(_this7.$t('操作成功'));
          // this.$store.commit('product/SET_MerProductClassify', []);
          _this7.loadingBtn = false;
          _this7.dialogVisible = false;
        }).catch(function () {
          _this7.loadingBtn = false;
          _this7.dialogVisible = false;
        });
      } else {
        this.loadingBtn = true;
        logistics.exportEditApi(this.ruleForm).then(function (data) {
          _this7.$emit('hideEditDialog');
          _this7.$message.success(_this7.$t('操作成功'));
          // this.$store.commit('product/SET_MerProductClassify', []);
          _this7.loadingBtn = false;
          _this7.dialogVisible = false;
        }).catch(function () {
          _this7.loadingBtn = false;
          _this7.dialogVisible = false;
        });
      }
    },
    clear: function clear() {
      this.ruleForm.name = '';
      this.ruleForm.sort = 0;
    }
  }
};