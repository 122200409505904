var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.isCopy
        ? _c("el-card", [
            _c(
              "div",
              { staticClass: "line-ht mb15" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("product.creatProduct.index.5u2pkl01ftc0")) +
                    "\n      "
                ),
                _vm.copyConfig.copyType && _vm.copyConfig.copyType == 1
                  ? _c("span", [
                      _vm._v(
                        "您当前剩余" +
                          _vm._s(_vm.copyConfig.copyNum) +
                          "条采集次数。\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.copyConfig.copyType && _vm.copyConfig.copyType != 1
                  ? _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          underline: false,
                          href: "https://help.jiefanghui.net/jiefanghui_java/2103903",
                          target: "_blank",
                        },
                      },
                      [_vm._v("如何配置密钥\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("product.creatProduct.index.5u2pkl01gk80")) +
                    " > " +
                    _vm._s(_vm.$t("product.creatProduct.index.5u2pkl01gmo0")) +
                    " >\n      " +
                    _vm._s(_vm.$t("product.creatProduct.index.5u2pkl01gok0")) +
                    " >\n      " +
                    _vm._s(_vm.$t("product.creatProduct.index.5u2pkl01gqk0")) +
                    "\n    "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.copyConfig.copyType && _vm.copyConfig.copyType != 1
              ? _c(
                  "div",
                  { staticClass: "mb15" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form,
                          callback: function ($$v) {
                            _vm.form = $$v
                          },
                          expression: "form",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("product.creatProduct.index.5u2pkl01gs80")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("product.creatProduct.index.5u2pkl01gtw0")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("product.creatProduct.index.5u2pkl01gvk0")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 4 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("product.creatProduct.index.5u2pkl01gx40")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 5 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("product.creatProduct.index.5u2pkl01gyk0")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.copyConfig.copyType
              ? _c(
                  "div",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: _vm.$t(
                            "product.creatProduct.index.5u2pkl01h0o0"
                          ),
                          size: "small",
                        },
                        model: {
                          value: _vm.url,
                          callback: function ($$v) {
                            _vm.url = typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "url",
                        },
                      },
                      [
                        _c("el-button", {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: [
                                "admin:product:copy:product",
                                "admin:product:import:product",
                              ],
                              expression:
                                "['admin:product:copy:product', 'admin:product:import:product']",
                            },
                          ],
                          attrs: {
                            slot: "append",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: { click: _vm.addProduct },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", {
                    attrs: {
                      title: _vm.$t("product.creatProduct.index.5u2pkl01h2o0"),
                    },
                  }),
                  _vm._v(" "),
                  _c("el-step", {
                    attrs: {
                      title: _vm.$t("product.creatProduct.index.5u2pkl01h480"),
                    },
                  }),
                  _vm._v(" "),
                  _c("el-step", {
                    attrs: {
                      title: _vm.$t("product.creatProduct.index.5u2pkl01h5w0"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "140px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01h800"
                            ),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "249",
                              placeholder: _vm.$t(
                                "product.creatProduct.index.5u2pkl01h9k0"
                              ),
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hbc0"
                            ),
                            prop: "cateIds",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.merProductClassify,
                              props: _vm.props2,
                              clearable: "",
                              "show-all-levels": false,
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.cateIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cateIds", $$v)
                              },
                              expression: "formValidate.cateIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hd00"
                            ),
                            prop: "categoryId",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.productClassify,
                              props: _vm.props1,
                              filterable: "",
                              clearable: "",
                              "show-all-levels": false,
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onChangeCategory },
                            model: {
                              value: _vm.formValidate.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "categoryId", $$v)
                              },
                              expression: "formValidate.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hec0"
                            ),
                            prop: "brandId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "selectLoadMore",
                                  rawName: "v-selectLoadMore",
                                  value: _vm.selectLoadMore,
                                  expression: "selectLoadMore",
                                },
                              ],
                              staticClass: "selWidth",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                loading: _vm.loading,
                                remote: "",
                                disabled:
                                  _vm.isDisabled ||
                                  !_vm.formValidate.categoryId,
                                "remote-method": _vm.remoteMethod,
                                placeholder: _vm.$t(
                                  "product.creatProduct.index.5u2pkl01hg00"
                                ),
                              },
                              model: {
                                value: _vm.formValidate.brandId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "brandId", $$v)
                                },
                                expression: "formValidate.brandId",
                              },
                            },
                            _vm._l(_vm.brandList, function (user) {
                              return _c("el-option", {
                                key: user.id,
                                attrs: { label: user.name, value: user.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hik0"
                            ),
                            prop: "unitName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "product.creatProduct.index.5u2pkl01hko0"
                              ),
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.unitName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "unitName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.unitName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "配送方式", prop: "shippingType" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.formValidate.shippingType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "shippingType",
                                    $$v
                                  )
                                },
                                expression: "formValidate.shippingType",
                              },
                            },
                            [_vm._v("自提")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.formValidate.shippingType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "shippingType",
                                    $$v
                                  )
                                },
                                expression: "formValidate.shippingType",
                              },
                            },
                            [_vm._v("快递")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hm80"
                            ),
                            prop: "intro",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "250",
                              rows: 3,
                              placeholder: _vm.$t(
                                "product.creatProduct.index.5u2pkl01hn80"
                              ),
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.intro,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "intro",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.intro",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hok0"
                            ),
                            prop: "image",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox acea-row",
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap(
                                    "1",
                                    undefined,
                                    undefined,
                                    undefined,
                                    300
                                  )
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isDisabled,
                                      expression: "!isDisabled",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "product.creatProduct.index.5u2pkl01hpw0"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hr00"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row row-middle mb5" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "76%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "product.creatProduct.index.5u2pkl01hs40"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                    multiple: _vm.multiples,
                                    disabled: _vm.isDisabled,
                                  },
                                  on: { change: _vm.changeGuarantee },
                                  model: {
                                    value: _vm.formValidate.guaranteeIdsList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "guaranteeIdsList",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.guaranteeIdsList",
                                  },
                                },
                                _vm._l(
                                  _vm.guaranteeNew,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c("el-switch", {
                                staticClass: "ml10",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": _vm.$t(
                                    "product.creatProduct.index.5u2pkl01ht80"
                                  ),
                                  "inactive-text": _vm.$t(
                                    "product.creatProduct.index.5u2pkl01huo0"
                                  ),
                                },
                                on: { change: _vm.onchangeIsShow },
                                model: {
                                  value: _vm.isShowGroup,
                                  callback: function ($$v) {
                                    _vm.isShowGroup = $$v
                                  },
                                  expression: "isShowGroup",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.guaranteeName, function (item, index) {
                            return _c(
                              "el-tag",
                              { key: index, staticClass: "mr10" },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.formValidate.shippingType == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "邮寄模板", prop: "tempId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.formValidate.tempId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "tempId", $$v)
                                    },
                                    expression: "formValidate.tempId",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.shippingType == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "预约到店：" } },
                            [
                              _c("el-switch", {
                                staticClass: "ml10",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": "开",
                                  "inactive-text": "开",
                                },
                                model: {
                                  value: _vm.formValidate.canAppointment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "canAppointment",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.canAppointment",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.shippingType == 2
                    ? _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "支持自提点/门店：",
                                prop: "merAddressIds",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    multiple: "",
                                    filterable: "",
                                    "allow-create": "",
                                    "default-first-option": "",
                                    placeholder: "请选择自提点/门店",
                                  },
                                  on: { change: _vm.changess },
                                  model: {
                                    value: _vm.formValidate.merAddressIds,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "merAddressIds",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.merAddressIds",
                                  },
                                },
                                _vm._l(_vm.options2, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "#909399" } }, [
                                _vm._v("注：单一自提点商户无需勾选"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hvw0"
                            ),
                            prop: "labelarr",
                          },
                        },
                        [
                          _c("keyword", {
                            attrs: {
                              labelarr: _vm.labelarr,
                              isDisabled: _vm.isDisabled,
                            },
                            on: { getLabelarr: _vm.getLabelarr },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01hx80"
                            ),
                            prop: "sliderImages",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.sliderImages,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "true" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isDisabled,
                                            expression: "!isDisabled",
                                          },
                                        ],
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.sliderImages.length < 10 &&
                              !_vm.isDisabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap(
                                            "2",
                                            undefined,
                                            undefined,
                                            undefined,
                                            300
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isDisabled,
                                  expression: "!isDisabled",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "product.creatProduct.index.5u2pkl01hpw0"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01i180"
                            ),
                            props: "specType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeSpec(
                                    _vm.formValidate.specType
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.specType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "specType", $$v)
                                },
                                expression: "formValidate.specType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: false },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "product.creatProduct.index.5u2pkl01i280"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "product.creatProduct.index.5u2pkl01i500"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01i680"
                            ),
                            props: "isSub",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangetype(
                                    _vm.formValidate.isSub
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.isSub,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isSub", $$v)
                                },
                                expression: "formValidate.isSub",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: true },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "product.creatProduct.index.5u2pkl01i7c0"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "product.creatProduct.index.5u2pkl01i8c0"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.specType && !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "product.creatProduct.index.5u2pkl01i9o0"
                                ),
                                prop: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.formValidate.selectRule,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "selectRule",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.ruleName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "product.creatProduct.index.5u2pkl01ias0"
                                          )
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addRule },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "product.creatProduct.index.5u2pkl01ic00"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            _vm._l(
                              _vm.formValidate.attr,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("span", { staticClass: "mr5" }, [
                                        _vm._v(_vm._s(item.attrName)),
                                      ]),
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveAttr(index)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "rulesBox" },
                                    [
                                      _vm._l(
                                        item.attrValue,
                                        function (j, indexn) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: indexn,
                                              staticClass: "mb5 mr10",
                                              attrs: {
                                                closable: "",
                                                size: "medium",
                                                "disable-transitions": false,
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.handleClose(
                                                    item.attrValue,
                                                    indexn
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(j) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      item.inputVisible
                                        ? _c("el-input", {
                                            ref: "saveTagInput",
                                            refInFor: true,
                                            staticClass: "input-new-tag",
                                            attrs: { size: "small" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.attrValue.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.attrValue,
                                                  "attrsVal",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "item.attrValue.attrsVal",
                                            },
                                          })
                                        : _c(
                                            "el-button",
                                            {
                                              staticClass: "button-new-tag",
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showInput(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "+ " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "product.creatProduct.index.5u2pkl01id80"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ],
                                    2
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 8,
                                    lg: 8,
                                    md: 12,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "product.creatProduct.index.5u2pkl01if00"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "product.creatProduct.index.5u2pkl01ig40"
                                          ),
                                        },
                                        model: {
                                          value: _vm.formDynamic.attrsName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDynamic,
                                              "attrsName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formDynamic.attrsName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 8,
                                    lg: 8,
                                    md: 12,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "product.creatProduct.index.5u2pkl01ih40"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "product.creatProduct.index.5u2pkl01iiw0"
                                          ),
                                        },
                                        model: {
                                          value: _vm.formDynamic.attrsVal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDynamic,
                                              "attrsVal",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formDynamic.attrsVal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 8,
                                    lg: 8,
                                    md: 12,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.createAttrName },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "product.creatProduct.index.5u2pkl01ik00"
                                              )
                                            ) + "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.offAttrName } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "product.creatProduct.index.5u2pkl01il40"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isBtn
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.addBtn },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "product.creatProduct.index.5u2pkl01img0"
                                          )
                                        ) + "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.attr.length > 0 &&
                  _vm.formValidate.specType &&
                  !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "product.creatProduct.index.5u2pkl01ink0"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.oneFormBatch,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01ioo0"
                                      ),
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3302088318
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01ipo0"
                                      ),
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  maxlength: "6",
                                                  type: "number",
                                                  min: 0,
                                                  max: scope.row.price,
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    return _vm.keyupEventBrokerage(
                                                      "brokerage",
                                                      scope.$index,
                                                      1
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.brokerage,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "brokerage",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.brokerage",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2755311892
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01iqw0"
                                      ),
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  maxlength: "6",
                                                  type: "number",
                                                  min: 0,
                                                  max: scope.row.price,
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    return _vm.keyupEventBrokerage(
                                                      "brokerageTwo",
                                                      scope.$index,
                                                      1
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.brokerageTwo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "brokerageTwo",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.brokerageTwo",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2979301652
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                        type:
                                          _vm.formThead[iii].title ===
                                          _vm.$t(
                                            "product.creatProduct.index.5u2pkl01is80"
                                          )
                                            ? "text"
                                            : "number",
                                        min: 0,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    maxlength: "6",
                                                    type: "number",
                                                    min: 0,
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub ? void 0 : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        align: "center",
                                        label: _vm.$t(
                                          "product.creatProduct.index.5u2pkl01itc0"
                                        ),
                                        "min-width": "80",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "submission",
                                            attrs: { type: "text" },
                                            on: { click: _vm.batchAdd },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "product.creatProduct.index.5u2pkl01iuk0"
                                                )
                                              ) + "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                    [
                      _vm.formValidate.specType === false
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.OneattrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01ioo0"
                                      ),
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.formValidate.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1357914119
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: _vm.$t(
                                              "product.creatProduct.index.5u2pkl01ipo0"
                                            ),
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        maxlength: "6",
                                                        disabled:
                                                          _vm.isDisabled,
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      nativeOn: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          return _vm.keyupEventBrokerage(
                                                            "brokerage",
                                                            scope.$index,
                                                            2
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.brokerage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerage",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerage",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2211629396
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: _vm.$t(
                                              "product.creatProduct.index.5u2pkl01iqw0"
                                            ),
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        maxlength: "6",
                                                        disabled:
                                                          _vm.isDisabled,
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      nativeOn: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          return _vm.keyupEventBrokerage(
                                                            "brokerageTwo",
                                                            scope.$index,
                                                            2
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .brokerageTwo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerageTwo",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerageTwo",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1931735860
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    maxlength: "6",
                                                    disabled: _vm.isDisabled,
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title ===
                                                      _vm.$t(
                                                        "product.creatProduct.index.5u2pkl01is80"
                                                      )
                                                        ? "text"
                                                        : "number",
                                                    min: 0,
                                                    type: "number",
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.params.id && _vm.showAll
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "product.creatProduct.index.5u2pkl01ivw0"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "default",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAllSku()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "product.creatProduct.index.5u2pkl01ixc0"
                                      )
                                    ) + "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.attr.length > 0 &&
                      _vm.formValidate.specType
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              class: _vm.isDisabled
                                ? "disLabel"
                                : "disLabelmoren",
                              attrs: {
                                label: _vm.$t(
                                  "product.creatProduct.index.5u2pkl01iyk0"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01ioo0"
                                      ),
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4035746379
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01ipo0"
                                      ),
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  disabled: _vm.isDisabled,
                                                  maxlength: "6",
                                                  type: "number",
                                                  min: 0,
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    return _vm.keyupEventBrokerage(
                                                      "brokerage",
                                                      scope.$index,
                                                      3
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.brokerage,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "brokerage",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.brokerage",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2462686325
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: _vm.$t(
                                        "product.creatProduct.index.5u2pkl01iqw0"
                                      ),
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  maxlength: "6",
                                                  disabled: _vm.isDisabled,
                                                  type: "number",
                                                  min: 0,
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    return _vm.keyupEventBrokerage(
                                                      "brokerageTwo",
                                                      scope.$index,
                                                      3
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.brokerageTwo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "brokerageTwo",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.brokerageTwo",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1915861493
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    type: "number",
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub ? void 0 : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isDisabled
                                    ? _c("el-table-column", {
                                        key: "3",
                                        attrs: {
                                          align: "center",
                                          label: _vm.$t(
                                            "product.creatProduct.index.5u2pkl01itc0"
                                          ),
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "submission",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delAttrTable(
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "product.creatProduct.index.5u2pkl01izk0"
                                                          )
                                                        ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1742162129
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1 && !_vm.isDisabled,
                      expression: "currentTab === 1 && !isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01j0o0"
                            ),
                          },
                        },
                        [
                          _c("Tinymce", {
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "content",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1 && _vm.isDisabled,
                      expression: "currentTab === 1 && isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01j0o0"
                            ),
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formValidate.content ||
                                  _vm.$t(
                                    "product.creatProduct.index.5u2pkl01j240"
                                  )
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "product.creatProduct.index.5u2pkl01j380"
                                ),
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 1,
                                  max: 9999,
                                  placeholder: _vm.$t(
                                    "product.creatProduct.index.5u2pkl01j4g0"
                                  ),
                                  disabled: _vm.isDisabled,
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.proving1($event)
                                  },
                                },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "sort",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "product.creatProduct.index.5u2pkl01j5g0"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "proCoupon",
                          attrs: {
                            label: _vm.$t(
                              "product.creatProduct.index.5u2pkl01j6k0"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.coupons,
                                function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "mr10 mb10",
                                      attrs: {
                                        closable: !_vm.isDisabled,
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleCloseCoupon(tag)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(tag.name) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.couponIds == null
                                ? _c("span", { staticClass: "mr15" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "product.creatProduct.index.5u2pkl01j240"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isDisabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: { size: "mini" },
                                      on: { click: _vm.addCoupon },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "product.creatProduct.index.5u2pkl01j800"
                                          )
                                        ) + "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission priamry_border",
                      on: { click: _vm.handleSubmitUp },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("product.creatProduct.index.5u2pkl01j900")
                        ) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab < 2,
                          expression: "currentTab < 2",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("product.creatProduct.index.5u2pkl01ja40")
                        ) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.checkPermi(["merchant:product:update"])
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                (_vm.currentTab === 2 ||
                                  _vm.$route.params.id) &&
                                !_vm.isDisabled,
                              expression:
                                "(currentTab === 2 || $route.params.id) && !isDisabled",
                            },
                          ],
                          staticClass: "submission",
                          attrs: { type: "primary", loading: _vm.loadingBtn },
                          on: {
                            click: function ($event) {
                              return _vm.handleSubmit("formValidate")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("product.creatProduct.index.5u2pkl01jb80")
                            ) + "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }