var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送方式:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.shippingTypeFilter,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "shippingTypeFilter", $$v)
                            },
                            expression: "tableFrom.shippingTypeFilter",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("自提"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("快递"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "门店/自提点:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          on: { change: _vm.addressDetailChange },
                          model: {
                            value: _vm.tableFrom.addressDetail,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "addressDetail", $$v)
                            },
                            expression: "tableFrom.addressDetail",
                          },
                        },
                        _vm._l(_vm.addressDetailList, function (item) {
                          return _c("el-option", {
                            key: item.addressDetail,
                            attrs: {
                              label: item.name,
                              value: item.addressDetail,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.checkPermi(["merchant:order:page:list"])
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("order.index.5u3ctbxex6w0") },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            _vm._l(_vm.fromType, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.text) + "\n              ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkPermi([
                    "merchant:order:status:num",
                    "merchant:order:page:list",
                  ])
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("order.index.5u3ctbxextg0") },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: { change: _vm.seachList },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "all" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.index.5u3ctbxexww0")) +
                                      " " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.all
                                          ? _vm.orderChartType.all
                                          : 0 + ")"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "notShipped" } },
                                [
                                  _vm._v(
                                    "未发货 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.notShipped
                                          ? _vm.orderChartType.notShipped
                                          : 0 + ")"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "spike" } },
                                [
                                  _vm._v(
                                    "待收货 " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.spike
                                          ? _vm.orderChartType.spike
                                          : 0 + ")"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "awaitVerification" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.index.5u3ctbxexzw0")) +
                                      "\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.verification
                                          ? _vm.orderChartType.verification
                                          : 0 + ")"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "receiving" } },
                                [
                                  _vm._v("已核销\n                "),
                                  _vm.orderChartType.receiving
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              "(" + _vm.orderChartType.receiving
                                                ? _vm.orderChartType.receiving
                                                : 0 + ")"
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "refunded" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.index.5u3ctbxey3k0")) +
                                      "\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.refunded
                                          ? _vm.orderChartType.refunded
                                          : 0 + ")"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "deleted" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.index.5u3ctbxey5g0")) +
                                      "\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.deleted
                                          ? _vm.orderChartType.deleted
                                          : 0 + ")"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkPermi(["merchant:order:page:list"])
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: _vm.$t("order.index.5u3ctbxey6w0") },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(
                                    _vm.tableFrom.dateLimit
                                  )
                                },
                              },
                              model: {
                                value: _vm.tableFrom.dateLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                                },
                                expression: "tableFrom.dateLimit",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text) + "\n              ")]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: _vm.$t("order.index.5u3ctbxey8g0"),
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkPermi(["merchant:order:page:list"])
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: _vm.$t("order.index.5u3ctbxeya00") },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: _vm.$t("order.index.5u3ctbxeybk0"),
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.tableFrom.orderNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tableFrom,
                                    "orderNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "tableFrom.orderNo",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.seachList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["merchant:order:verification"],
                              expression: "['merchant:order:verification']",
                            },
                          ],
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.batchDelivery },
                        },
                        [_vm._v("批量发货\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["merchant:order:verification"],
                              expression: "['merchant:order:verification']",
                            },
                          ],
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.onWriteOff },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("order.index.5u3ctbxeyd80")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["merchant:export:order:excel"],
                              expression: "['merchant:export:order:excel']",
                            },
                          ],
                          attrs: { size: "small" },
                          on: { click: _vm.exports },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("order.index.5u3ctbxeyeo0")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt20" }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": { fontWeight: "bold" },
                "row-key": function (row) {
                  return row.orderNo
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("order.index.5u3ctbxeyg80"),
                  "min-width": "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "acea-row" },
                          [
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.type === 1,
                                    expression: "scope.row.type === 1",
                                  },
                                ],
                                attrs: { color: "#f6ae02" },
                              },
                              [_vm._v("[视频号]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.type === 2,
                                    expression: "scope.row.type === 2",
                                  },
                                ],
                                attrs: { color: "#1890ff" },
                              },
                              [_vm._v("[秒杀]")]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              class:
                                parseInt(scope.row.refundStatus) > 0
                                  ? "red"
                                  : "",
                              staticStyle: {
                                display: "block",
                                "margin-left": "5px",
                              },
                              domProps: {
                                textContent: _vm._s(scope.row.orderNo),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: parseInt(scope.row.refundStatus) > 0,
                                expression:
                                  "parseInt(scope.row.refundStatus) > 0",
                              },
                            ],
                            staticStyle: { color: "#ed4014", display: "block" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("orderRefundStatusFilter")(
                                  scope.row.refundStatus
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.isUserDel,
                                expression: "scope.row.isUserDel",
                              },
                            ],
                            staticStyle: { color: "#ed4014", display: "block" },
                          },
                          [_vm._v(_vm._s(_vm.$t("order.index.5u3ctbxeyiw0")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("order.index.5u3ctbxeyk00"),
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { class: scope.row.isLogoff == true ? "red" : "" },
                          [_vm._v(_vm._s(scope.row.nickName))]
                        ),
                        _vm._v(" "),
                        scope.row.isLogoff == true
                          ? _c(
                              "span",
                              {
                                class: scope.row.isLogoff == true ? "red" : "",
                              },
                              [_vm._v("|")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.isLogoff == true
                          ? _c("span", { staticClass: "red" }, [
                              _vm._v("(已注销)"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "totalNum", label: "商品总数" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "proTotalPrice", label: "商品总价" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "totalPostage", label: "支付邮费" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "120", prop: "couponPrice", label: "优惠金额" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "120", prop: "addressDetail", label: "自提点" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(scope.row.addressDetail)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "130",
                  prop: "receivingTime",
                  label: "核销时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "130",
                  prop: "appointTime",
                  label: "预约时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "totalPostage", label: "配送方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.shippingType === 1 ? "快递" : "自提"
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userAddress", label: "收货地址" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "realName", label: "收件人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userPhone", label: "收件手机号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payPrice",
                  label: _vm.$t("order.index.5u3ctbxeyls0"),
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("order.index.5u3ctbxeyn80"),
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("payTypeFilter")(scope.row.payType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("order.index.5u3ctbxeyp40"),
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.refundStatus === 3
                          ? _c("span", { staticClass: "fontColor3" }, [
                              _vm._v(
                                _vm._s(_vm.$t("order.index.5u3ctbxey3k0"))
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderStatusFilter")(scope.row.status)
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("order.index.5u3ctbxeyqk0"),
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.merRemark))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("order.index.5u3ctbxeyss0"),
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("order.index.5u3ctbxeytw0"),
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        (scope.row.status === 1 || scope.row.status === 2) &&
                        parseFloat(scope.row.refundStatus) < 3
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["merchant:order:send"],
                                    expression: "['merchant:order:send']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendOrder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("发货\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:order:info"])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onOrderDetails(scope.row.orderNo)
                                  },
                                },
                              },
                              [_vm._v("订单详情\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("order.index.5u3ctbxeyvk0"))
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi(["merchant:order:mark"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderMark(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("订单备注\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.isUserDel === 1 &&
                                _vm.checkPermi(["merchant:order:delete"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除订单\n              ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("order.index.5u3ctbxeyxc0")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("order.index.5u3ctbxeyyo0"),
            visible: _vm.dialogVisibleJI,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleJI = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "oid",
                  align: "center",
                  label: "ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "changeMessage",
                  label: _vm.$t("order.index.5u3ctbxeyyo0"),
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("order.index.5u3ctbxeyzw0"),
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量发货",
            visible: _vm.showbatchDelivery,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showbatchDelivery = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                padding: "15px",
                "background-color": "#fff6ed",
                color: "#d1b485",
                "border-radius": "5px",
              },
            },
            [
              _c("div", { staticStyle: { "margin-top": "5px" } }, [
                _vm._v("步骤一 导出发货单"),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "5px" } }, [
                _vm._v("步骤二 发货单中填写物流单号"),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "5px" } }, [
                _vm._v("步骤三 将发货单上传"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.forms } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "width100",
                  staticStyle: { "margin-top": "15px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "" },
                      on: { click: _vm.batchDelivery2 },
                    },
                    [_vm._v("导出发货单 ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        limit: 1,
                        accept: ".xlsx, .xls",
                        headers: _vm.upload.headers,
                        "http-request": _vm.handleUploadForm,
                        "on-success": _vm.handleFileSuccess,
                        "file-list": _vm.fileList,
                        "on-exceed": _vm.onExceed,
                        "auto-upload": "false",
                        drag: "",
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [_vm._v("Tips：此处选择快递公司查询编码")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { filterable: "", placeholder: "快递编码查看" },
              model: {
                value: _vm.expressValue,
                callback: function ($$v) {
                  _vm.expressValue = $$v
                },
                expression: "expressValue",
              },
            },
            _vm._l(_vm.express, function (item) {
              return _c("el-option", {
                key: item.code,
                attrs: { label: item.code, value: item.name },
              })
            }),
            1
          ),
          _vm._v("\n     " + _vm._s(_vm.expressValue) + "\n    "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [_c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")])],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderNo: _vm.orderNo },
      }),
      _vm._v(" "),
      _c("order-send", {
        ref: "send",
        attrs: { orderNo: _vm.orderNo, userPhone: _vm.userPhone },
        on: { submitFail: _vm.seachList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }