"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _baseInfo = _interopRequireDefault(require("./components/baseInfo"));
var _ranking = _interopRequireDefault(require("./components/ranking"));
var _gridMenu = _interopRequireDefault(require("./components/gridMenu"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    baseInfo: _baseInfo.default,
    gridMenu: _gridMenu.default,
    ranking: _ranking.default
  },
  methods: {
    checkPermi: _permission.checkPermi
  }
};