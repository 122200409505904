"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _amapJsapiLoader = _interopRequireDefault(require("@amap/amap-jsapi-loader"));
var _elementUi = require("element-ui");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "map-view",
  data: function data() {
    return {
      placeSearch: null,
      input: '',
      serarchList: [],
      marker: {}
    };
  },
  props: ['longitude', 'latitude', 'addressDetail'],
  mounted: function mounted() {
    this.initAMap();
  },
  unmounted: function unmounted() {
    // this.map?.destroy();
    if (this.map) {
      this.map.destroy();
    }
  },
  methods: {
    initAMap: function initAMap() {
      var _this2 = this;
      console.log(this.longitude, this.latitude);
      window._AMapSecurityConfig = {
        securityJsCode: "7e0a9d32cc62ed339b20b1b76fc84c9f"
      };
      _amapJsapiLoader.default.load({
        key: "66c8ff4a0c6d103888daabe5c6de79d6",
        // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.AutoComplete", "AMap.PlaceSearch", "AMap.Geocoder"] //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      }).then(function (AMap) {
        var newA = _this2.longitude ? Number(_this2.longitude) : 120.268044;
        var newB = _this2.latitude ? Number(_this2.latitude) : 30.178743;
        var newC = _this2.addressDetail ? _this2.addressDetail : '萧山北干';
        _this2.map = new AMap.Map("container", {
          enableHighAccuracy: true,
          viewMode: "3D",
          // 是否为3D地图模式
          zoom: 16,
          // 初始化地图级别
          center: [newA, newB] // 初始化地图中心点位置
        });
        _this2.placeSearch = new AMap.PlaceSearch({
          pageSize: 8,
          //单页显示结果条数
          pageIndex: 1,
          //页码
          map: _this2.map,
          //展现结果的地图实例
          panel: "my-panel",
          //结果列表将在此容器中进行展示。
          autoFitView: true //是否自动调整地图视野使绘制的 Marker 点都处于视口的可见范围
        });
        _this2.placeSearch.on('markerClick', function (e) {
          var a = {
            latitude: e.data.location.lat,
            longitude: e.data.location.lng,
            addressDetail: e.data.address
          };
          _this2.$emit('setLocation', a);
        });
        _this2.placeSearch.on('listElementClick', function (e) {
          var a = {
            latitude: e.data.location.lat,
            longitude: e.data.location.lng,
            addressDetail: e.data.address
          };
          _this2.$emit('setLocation', a);
        });
        _this2.searchMap();
        _this2.map.on("click", _this2.handleMapClick);
      }).catch(function (e) {
        console.log(e);
      });
    },
    inputChane: function inputChane() {
      var _this3 = this;
      var that = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        if (_this3.input != '') {
          _this3.auto.search(_this3.input, function (status, result) {
            console.log(status, result);
            that.serarchList = result.tips;
          });
        }
      }, 300);
    },
    handleMapClick: function handleMapClick(e) {
      _elementUi.Loading.service();
      var _this = this;
      var geocoder = new AMap.Geocoder({
        city: '010',
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        extensions: 'all'
      });
      geocoder.getAddress([e.lnglat.lng, e.lnglat.lat], function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          _this.placeSearch.search(result.regeocode.formattedAddress);
          _elementUi.Loading.service().close();
        }
      });
      // 发送逆地理编码请求
    },
    // 关键字查询
    searchMap: function searchMap() {
      var _this = this;
      // 搜索框自动完成类
      _this.auto = new AMap.AutoComplete({
        // input: "searchInput", // 使用联想输入的input的id
      });
    },
    selectSite: function selectSite(e) {
      console.log(e);
      this.placeSearch.search(e.poi.name);
    },
    onSearch: function onSearch(text) {
      this.serarchList = [];
      this.placeSearch.search(text);
    }
  }
};