"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = _interopRequireDefault(require("@/settings"));
var _cache = _interopRequireDefault(require("@/plugins/cache"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var showSettings = _settings.default.showSettings,
  tagsView = _settings.default.tagsView,
  fixedHeader = _settings.default.fixedHeader,
  sidebarLogo = _settings.default.sidebarLogo,
  topNav = _settings.default.topNav,
  sideTheme = _settings.default.sideTheme,
  navIcon = _settings.default.navIcon;
var storageSetting = _cache.default.local.has('layout-setting') ? _cache.default.local.getJSON('layout-setting') : '';
var state = {
  theme: storageSetting.theme || '#409EFF',
  sideTheme: storageSetting.sideTheme || sideTheme,
  topNav: storageSetting.topNav === undefined ? topNav : storageSetting.topNav,
  showSettings: showSettings,
  tagsView: storageSetting.tagsView === undefined ? tagsView : storageSetting.tagsView,
  fixedHeader: storageSetting.fixedHeader === undefined ? fixedHeader : storageSetting.fixedHeader,
  sidebarLogo: storageSetting.sidebarLogo === undefined ? sidebarLogo : storageSetting.sidebarLogo,
  navIcon: storageSetting.navIcon === undefined ? navIcon : storageSetting.navIcon
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};