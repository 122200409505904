"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.businessData = businessData;
exports.chartBuyApi = chartBuyApi;
exports.chartOrder30Api = chartOrder30Api;
exports.chartOrderMonthApi = chartOrderMonthApi;
exports.chartOrderWeekApi = chartOrderWeekApi;
exports.chartOrderYearApi = chartOrderYearApi;
exports.chartUserApi = chartUserApi;
exports.pageviewDataApi = pageviewDataApi;
exports.payDataApi = payDataApi;
exports.viewModelApi = viewModelApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// 首页数据概览
function viewModelApi() {
  return (0, _request.default)({
    url: '/admin/merchant/statistics/home/index',
    method: 'GET'
  });
}

// 用户曲线图
function chartUserApi() {
  return (0, _request.default)({
    url: '/admin/statistics/home/chart/user',
    method: 'get'
  });
}

// 用户购买统计
function chartBuyApi() {
  return (0, _request.default)({
    url: '/admin/statistics/home/chart/user/buy',
    method: 'get'
  });
}

// 订单量趋势 30天
function chartOrder30Api() {
  return (0, _request.default)({
    url: '/admin/statistics/home/chart/order',
    method: 'get'
  });
}

// 订单量趋势 月
function chartOrderMonthApi() {
  return (0, _request.default)({
    url: '/admin/statistics/home/chart/order/month',
    method: 'get'
  });
}

// 订单量趋势 周
function chartOrderWeekApi() {
  return (0, _request.default)({
    url: '/admin/statistics/home/chart/order/week',
    method: 'get'
  });
}

// 订单量趋势 年
function chartOrderYearApi() {
  return (0, _request.default)({
    url: '/admin/statistics/home/chart/order/year',
    method: 'get'
  });
}

// 首页经营数据
function businessData() {
  return (0, _request.default)({
    url: '/admin/merchant/statistics/home/operating/data',
    method: 'get'
  });
}

// 商品浏览量排行榜
function pageviewDataApi() {
  return (0, _request.default)({
    url: '/admin/merchant/statistics/home/product/pageview/ranking',
    method: 'get'
  });
}

// 商品支付排行榜
function payDataApi() {
  return (0, _request.default)({
    url: '/admin/merchant/statistics/home/product/pay/ranking',
    method: 'get'
  });
}