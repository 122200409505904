"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var logistics = _interopRequireWildcard(require("@/api/logistics.js"));
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'CompanyList',
  components: {
    parser: _Parser.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      // 表单
      formConf: {
        fields: []
      },
      form: {
        keywords: ''
      },
      tableData: [],
      page: 1,
      limit: 20,
      loading: false,
      dialogVisible: false,
      fromType: 'add',
      formData: {
        status: false
      },
      isCreate: 0,
      formShow: false,
      editId: 0
    };
  },
  created: function created() {
    this.getExpressList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //  获取物流公司列表
    getExpressList: function getExpressList() {
      var _this = this;
      this.loading = true;
      logistics.expressAllApi().then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      }).catch(function () {
        _this.loading = false;
      });
    }
  }
};